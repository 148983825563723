import '../App.css';
import '../styles/x_base.css';
//import '../styles/x_red.css';
import Section from '../components/Section';
import Headery from '../components/Headery';
import {
  React,
  useState
} from 'react';
import DefaultElement from '../components/DefaultElement';
import Main from '../components/Main';
import { Toaster } from 'react-hot-toast';
import Fade from 'react-reveal/Fade';

function Base() {
  
  const [v, setV] = useState(true);

  return <div style={{"height": "100vh", "pointerEvents": "none"}}>
    <Fade in={v && window.innerWidth > 1000} delay={100}>
    <div className='fiverr-turnup'>
      <div className='exit-fiverr' onClick={()=>{
        setV(false);
      }}></div>
      <div className='welcome-title' style={{"fontSize": "24px"}}>Visit me on Fiverr</div>
      <div className='fiverr-tag' onClick={()=>{
        window.open("https://fiverr.com/valentinahrend", "_blank");
      }}>Let's go</div>
    </div>
    </Fade>
    <Section>
        <Main></Main>
    </Section>
    </div>;
}

export default Base;
