import { useState } from "react";
import Pulse from 'react-reveal/Pulse';

const SkillTag = ({language, onSelect, selectx}) => {

    const [x, setX] = useState(2);
    if(language == "1"){
        return <div className="skilltag" style={{"pointerEvents": "none"}}>
            <div className="skilltag-inner">
                {"Android"}
            </div>
        </div>;
    }
    if(language == "Android" && x == 2){
        setX(1);
    }
    if(x == 1 && selectx != language){
        setX(0);
    }
    return <Pulse when={x == 1}><div className={"skilltag" + (x == 1 ? " skilltag-active" : "")} onClick={()=>{
        /*for (const element of document.getElementsByClassName("skilltag-active")) {
            element.classList.remove("skilltag-active");
        }*/
        
        onSelect(language);
        setX(1);
    }}>
        <div className="skilltag-inner" style={{"cursor": "pointer"}}>
            {language}
        </div>
    </div></Pulse>;
}
export default SkillTag;