
function DefaultElement() {

    new Promise(x => setTimeout(x, 3000)).then(y => {
        window.location.replace("https://valentinahrend.com");
    });

    return <div id='central-x'>
        <main className="def-main">
        <h1 className="def-header">Site not found</h1>
        <p className="def-sub">You will be redirected to the main page...</p>
    </main>
    </div>
}
export default DefaultElement;