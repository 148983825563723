import '../App.css';
import Section from '../components/Section';
import Headery from '../components/Headery';
import {
  React,
  useState
} from 'react';
import DefaultElement from '../components/DefaultElement';
import Main from '../components/Main';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Base from './Base';
import Hello from './Hello';
import Rainbow from './Rainbox';
import AltIFrame from './AltIframe';
import Crash from './Crash';
import Eva from './Eva';
import Eva2 from './Eva2';
import Impress from './Impress';

function App() {
  


  return <BrowserRouter>
    <Routes>
      <Route index element={<Base/>}/>
      <Route path="/hello" element={<Hello></Hello>}/>
      <Route path="/rainbow" element={<Rainbow></Rainbow>}/>
      <Route path="/old" element={<AltIFrame></AltIFrame>}/>
      <Route path="/crash" element={<Crash></Crash>}/>
      <Route path="/impress" element={<Impress></Impress>}></Route>
      <Route path="/eva" element={<Eva></Eva>}></Route>
      <Route path="/mytruelove" element={<Eva2></Eva2>}></Route>
      <Route path="*" element={<DefaultElement/>}/>
    </Routes>
  </BrowserRouter>;
}

export default App;
