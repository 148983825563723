import DefaultElement from "../components/DefaultElement";

function Eva() {


    const urlSearch = new URLSearchParams(window.location.search);
    if(urlSearch.get("x") !== "abc123") {
        return <DefaultElement></DefaultElement>;
    }

    return (<div style={{"width": "100vw", "height": "100vh"}}>
        <iframe width={"100%"} height={"100%"} style={{"height": "100vh"}} src="https://www.desmos.com/calculator/kz8cosomji"></iframe>
    </div>);
}
export default Eva;