export default function Impress() {
    return <div style={{"position": "absolute", "top": "0px", "left": "0px", "width": "100vw", "height": "100vh", "background": "linear-gradient(45deg, rgba(74, 136, 98, 0.2) 0%, rgba(23, 96, 51, 0.2) 100%)", "padding": "10px"}}>
        <h2>Impressum</h2>
        <p>

            {"Jim-Linus Valentin Ahrend Software Production (VASP) 2023\n"}
            {"by Valentin Ahrend\n"}
            {"valentinahrend@gmail.com\n"}
            {""}

        </p>
    </div>
};