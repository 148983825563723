import { useEffect } from "react";
import "../styles/rainbox.css";

function Rainbow() {
    const amount = 30;
    const CSS_COLOR_NAMES = [
        "red",
        "green",
        "blue",
        "lime",
        "yellow",
        "orange",
        "aqua",
        "orange",
        "brown",
        "violet",
        "azure",
        "red",
        "green",
        "blue",
        "lime",
        "yellow",
        "orange",
        "aqua",
        "orange",
        "brown",
        "violet",
        "azure",
        "red",
        "green",
        "blue",
        "lime",
        "yellow",
        "orange",
        "aqua",
        "orange",
        "brown",
        "violet",
        "azure",
      ];
    useEffect(()=>{
        document.getElementById("root").style.overflow = "hidden";
        async function x() {
            var ix = 0;
            for (let index = 0; index < amount; index++) {
                var yx = index + ix;
                document.getElementById("r" + index.toString()).style.backgroundColor = CSS_COLOR_NAMES[yx % amount];
            }
        while (true) {
            let x = 10000 * Math.pow(ix + 2, -1);
            ix = ix + 1;
            await new Promise(xy => setTimeout(xy, x));
            for (let index = 0; index < amount; index++) {
                var yx = index + ix;
                
                //document.getElementById("r" + index.toString()).style.transition = (x/1000).toString()+"s";
                document.getElementById("r" + index.toString()).style.backgroundColor = CSS_COLOR_NAMES[yx % amount];
            }
        }    
        }
        x();
    }, []);

    function rainbowElements(id) {
        if(id >= amount){
            return "";
        }
        const elements = [];
        let f = 100 / amount * id;
        elements.push(<div style={{"borderRadius":"100%", "width": (100-f).toString()+"vmin", "height": (100-f).toString()+"vmin", "display": "flex", "margin": "auto"}} id={"r" + id}>{rainbowElements(id + 1, amount)}</div>);    
        return elements;
    }

    return <div style={{"width": "100%", "height": "100vh", "display": "flex", "justifyContent": "center", "alignContent": "center", "display": "flex", "scale":"1.5"}} id="r-1">
        <div style={{"width": "100%", "height": "100vh", "display": "flex", "justifyContent": "center", "alignContent": "center", "margin": "auto"}} id="r0">
        {rainbowElements(1, amount)}
    </div></div>;
}
export default Rainbow;