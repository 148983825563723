import React from "react";
import ReactDOM from 'react-dom/client';
import { render } from "react-dom";
import toast, { Toaster, useToaster } from "react-hot-toast";
import "../styles/rainbox.css";
import { async } from "@firebase/util";
const mouseClickEvents = ['mousedown', 'click', 'mouseup'];


function generateA() {
    const as = [];
    for (let index = 0; index < 10; index++) { 
        as.push(<a id={index.toString()} href={
            "javascript:window.open('https://www.youtube.com/watch?v=G1IbRujko-A', 'newwindow', 'width=50,height=50,x="+(index * 100).toString()+"')"}></a>);
    }
    return as;
}

function Crash() {
    const sounds = ["https://drive.google.com/uc?id=1y-TyR5AHSiebXDn5giS7oAZ3UfvZSFyN&export=download", "https://drive.google.com/uc?id=1C6pzUrZPaxiohA9aHPMIkMxaUCNu8Lfw&export=download", "https://drive.google.com/uc?id=1HyKvTQzgSpwQDJo75_WH6O-np6CaNswX&export=download", "https://drive.google.com/uc?id=1NVr6a-j9XtvqolYieHQ7oNMXrOuGAWr3&export=download"];
    return <div className="crash">
        <div className="crash-element">
            This page does not take any responisbility for the following actions caused by the buttons below
            <div className="crash-button" onClick={()=>{
                var audio = new Audio(sounds[0]);
                audio.volume = 1.0;
                audio.play();
                var audio = new Audio(sounds[1]);
                audio.volume = 1.0;
                audio.play();
                var audio = new Audio(sounds[2]);
                audio.volume = 1.0;
                audio.play();
                var audio = new Audio(sounds[3]);
                audio.volume = 1.0;
                audio.play();
            }}>
                MUSIC
            </div>
            <a className="crash-button" onClick={async ()=>{
                for (let index = 0; index < document.getElementsByTagName("a").length; index++) {
                    const element = document.getElementsByTagName("a")[index];
                    console.log(element);
                    element.click();
                }
                var ixx = 0;
                while (true) {
                    await new Promise(x => setTimeout(x, 100));
                    const element = document.getElementById("1");
                    let xy = element.getAttribute("href");
                    xy = xy.replace("newwindow", "newwindow" + ixx.toString());
                    element.setAttribute("href", xy);
                    console.log(xy);
                    element.click();
                    ixx = ixx + 1;
                }
            }}>
                APP
            </a>
            <div className="crash-button" onClick={()=>{
                for (let index = 0; index < 400; index++) {
                    window.open("https://www.youtube.com/watch?v=G1IbRujko-A");
                } 
            }}>
                RAM
            </div>
        </div>
        <div>
            {generateA()}
        </div>
    </div>; 
}
export default Crash;