import ReactDOM from 'react-dom/client';
import './index.css';
import App from './sites/App';
import reportWebVitals from './reportWebVitals';
import * as React from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAp4bpVQxfyeroxdI3437eOAX65vX_gcGc",
  authDomain: "valentinahrend-ac6bb.firebaseapp.com",
  projectId: "valentinahrend-ac6bb",
  storageBucket: "valentinahrend-ac6bb.appspot.com",
  messagingSenderId: "132053204383",
  appId: "1:132053204383:web:aed16a38e53f606d4dd1aa",
  measurementId: "G-4RN4XZCNC2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <React.Fragment>
  <App></App>
  </React.Fragment>
);
window.addEventListener('resize', root.render(
  <React.Fragment>
  <App></App>
  </React.Fragment>
));
reportWebVitals();